
const validateForm = (showToast, formData, descriptionHTML, selectedVideo, hashtags, mentions, fixedReward, variableReward) => {
  const validations = [
    {
      condition: formData.name === '',
      message: 'Debes poner un nombre al reto',
    },
    {
      condition: descriptionHTML === '',
      message: 'Debes poner una descripción al reto',
    },
    {
      condition: formData.launchDate === '',
      message: 'Debes poner una fecha de lanzamiento del reto',
    },
    {
      condition: formData.budget === '',
      message: 'Debes introducir un presupuesto válido',
    },
    {
      condition: fixedReward === 0,
      message: 'Debes poner una recompensa fija mayor',
    },
    {
      condition: variableReward === 0,
      message: 'Debes poner una recompensa variable mayor',
    },
  ];

  for (const validation of validations) {
    if (validation.condition) {
      showToast(validation.message, 'error', 2000);
      return false; 
    }
  }

  return true; 
};

export default validateForm;