import { Flex, Image, Text } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';

import CruwiLogo from '../../Assets/Images/cruwi-logo.svg';

const pulse = keyframes`
  0% { transform: scale(1.1); }
  50% { transform: scale(1.3); }
  100% { transform: scale(1.1); }
`;

const animation = `${pulse} 2s ease-in-out infinite`;

export default function LogoLoader2() {
  return (
    <Flex
      minH="80dvh"
      minW="100%"
      flexDir="column"
      justifyContent="center"
      alignItems="center"
    >
      <Flex flexDir="column" textAlign="center">
        <Image
          animation={animation}
          margin="0 auto"
          src={CruwiLogo}
          alt="Cruwi Logo"
        />
        <Text mt={2}>Cargando información de los retos...</Text>
      </Flex>
    </Flex>
  );
}
