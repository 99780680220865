import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Flex,
  Text,
  Card,
  Heading,
  Icon,
  Button,
  Image,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';

import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

import UGCBanner from '../../../Assets/Images/ugc-tiktok.png';
import UGCHowItWorksCRUWI from '../../../Assets/Images/how-ugc.svg';
import ArrowImage from '../../../Assets/Images/left-arrow.png';

const videos = [
  'https://cruwi-creators.s3.eu-west-3.amazonaws.com/videos-ugc-web/copy_85452F3B-ECBF-4C20-A812-26749B855FA9.mov',
  'https://cruwi-creators.s3.eu-west-3.amazonaws.com/videos-ugc-web/Parches-UKLASH.mp4',
  'https://cruwi-creators.s3.eu-west-3.amazonaws.com/videos-ugc-web/copy_7DFE7CB5-E329-4F5D-9B30-23763FDFEC45+copia.mov',
];

const VideoCarousel = ({ videos }) => {
  const [current, setCurrent] = useState(0);

  const nextVideo = () => setCurrent((current + 1) % videos.length);
  const prevVideo = () =>
    setCurrent((current - 1 + videos.length) % videos.length);

  return (
    <Flex
      position="relative"
      borderRadius="8px"
      justifyContent="center"
      alignItems="center"
    >
      <Box borderRadius="8px" as="video" src={videos[current]} controls />
      <Flex
        p={3}
        width="100%"
        justifyContent="space-between"
        zIndex="999999"
        position="absolute"
      >
        <Button
          right={0}
          backgroundColor="white"
          color="black"
          mr={2}
          size="xs"
          onClick={prevVideo}
        >
          <Icon as={MdKeyboardArrowLeft} />
        </Button>
        <Button
          left={0}
          backgroundColor="white"
          color="black"
          ml={2}
          size="xs"
          onClick={nextVideo}
        >
          <Icon as={MdKeyboardArrowRight} />
        </Button>
      </Flex>
    </Flex>
  );
};

export default function DashboardUGC() {
  const navigate = useNavigate();
  const navigateTo = path => {
    navigate(path);
  };
  return (
    <>
      <Box bg="white" p="5" borderBottom="1px solid #e2e8f0">
        <Flex alignItems="center" justifyContent="space-between">
          <Text fontWeight="bold" fontSize="24px">
            Aprende sobre UGC
          </Text>
        </Flex>
      </Box>

      {/* MAIN CONTENT */}
      <Box p="20px 20px 20px 20px">
        {/* Banner UGC */}
        <Box maxW={800} margin="0 auto 20px auto">
          <Card
            borderRadius="8px"
            height={200}
            backgroundSize="cover"
            backgroundImage={UGCBanner}
          ></Card>
        </Box>

        {/* What is UGC section + video carousel */}
        <Flex position="relative" maxW={800} margin="0 auto 20px auto">
          <Box width="500px" mr="20px">
            <Card minH="520px" p={5}>
              <Heading mb={5} fontSize="24px">
                ¿Qué es UGC?
              </Heading>

              <Text fontSize="sm" mb={3}>
                Los videos de Contenido Generado por Usuarios o UGC involucran a
                usuarios reales que presentan un producto o servicio de una
                manera única, a menudo a través de testimonios, unboxings,
                tutoriales o contenido de estilo de vida. Esta autenticidad
                puede crear una experiencia más confiable y atractiva para los
                espectadores, ya que el contenido se percibe como genuino y no
                excesivamente pulido por los estándares de publicidad
                profesional.
              </Text>

              <Text fontSize="sm" mb={3}>
                Además de ser más auténtico y valioso para los consumidores, el
                UGC también puede ahorrar tiempo y recursos a las marcas,
                eliminando la necesidad de invertir en costosas sesiones
                fotográficas y equipos de producción. Al mostrar la experiencia
                de otros usuarios con los productos o servicios de una marca,
                los clientes potenciales pueden sentirse más seguros al tomar
                una decisión de compra, generando confianza y credibilidad entre
                ellos.
              </Text>

              <Text fontSize="sm">
                Esto lleva al comprador en un viaje emocional hacia la idea de
                que lo que está viendo es algo que debe tener, permitiéndoles
                imaginarse a sí mismos usando el producto. Los consumidores son
                más propensos a comprometerse con el contenido de personas
                reales, lo que puede llevar a la inversión emocional en un
                producto o marca.
              </Text>
            </Card>
          </Box>
          <Box width="300px">
            <Card borderRadius="10px" p={0}>
              <VideoCarousel videos={videos} />
            </Card>
          </Box>
          <Box right={-100} position="absolute">
            <Text
              p="3px 10px"
              borderRadius="10px"
              color="white"
              bg="black"
              transform="rotate(-30deg)"
              fontSize="18px"
              fontWeight="bold"
            >
              Ejemplos UGC
            </Text>
            <Image transform="rotate(-0.25turn)" w={100} src={ArrowImage} />
          </Box>
        </Flex>

        {/* How can help you section */}
        <Box maxW="800px" margin="0 auto 20px auto">
          <Card borderRadius="8px" p={5} pb={10}>
            <Heading mb="20px" fontSize="24px">
              ¿Cómo puede ayudarte?
            </Heading>

            <Accordion allowMultiple>
              <AccordionItem mb="5px" border="none">
                <AccordionButton
                  minH="40px"
                  p={0}
                  justifyContent="space-between"
                  borderBottom="1px solid white"
                  _hover={{ bg: 'none' }}
                >
                  <Heading size="xs" textTransform="uppercase">
                    Autenticidad y confianza
                  </Heading>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel mt="5px" p={0}>
                  <Text fontSize="sm">
                    Este tipo de contenido es valioso porque está creado por
                    personas reales que han utilizado sus productos. Esto aporta
                    un nivel de autenticidad y confianza que el contenido de
                    marketing tradicional no puede igualar. Es más probable que
                    los clientes potenciales confíen en las recomendaciones de
                    otros consumidores que en la publicidad directa de la marca.
                  </Text>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem mb="5px" border="none">
                <AccordionButton
                  minH="40px"
                  p={0}
                  justifyContent="space-between"
                  borderBottom="1px solid white"
                  _hover={{ bg: 'none' }}
                >
                  <Heading size="xs" textTransform="uppercase">
                    Compromiso y comunidad
                  </Heading>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel mt="5px" p={0}>
                  <Text fontSize="sm">
                    Cuando usuarios reales comparten sus experiencias con sus
                    productos animan a otros a participar y compartir.
                  </Text>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem mb="5px" border="none">
                <AccordionButton
                  minH="40px"
                  p={0}
                  justifyContent="space-between"
                  borderBottom="1px solid white"
                  _hover={{ bg: 'none' }}
                >
                  <Heading size="xs" textTransform="uppercase">
                    Contenido fresco y variado
                  </Heading>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel mt="5px" p={0}>
                  <Text fontSize="sm">
                    El UGC ofrece una variedad de perspectivas y estilos, ya que
                    cada usuario aporta su propia voz y creatividad. Esto
                    enriquece el ecosistema de contenido de una marca.
                  </Text>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem mb="5px" border="none">
                <AccordionButton
                  minH="40px"
                  p={0}
                  justifyContent="space-between"
                  borderBottom="1px solid white"
                  _hover={{ bg: 'none' }}
                >
                  <Heading size="xs" textTransform="uppercase">
                    Credibilidad y prueba social
                  </Heading>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel mt="5px" p={0}>
                  <Text fontSize="sm">
                    La gente tiende a confiar más en las recomendaciones de
                    amigos, familiares y otros consumidores que en la publicidad
                    tradicional. El UGC actúa como una recomendación
                    peer-to-peer, lo que puede aumentar la credibilidad de una
                    marca.
                  </Text>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem mb="5px" border="none">
                <AccordionButton
                  minH="40px"
                  p={0}
                  justifyContent="space-between"
                  borderBottom="1px solid white"
                  _hover={{ bg: 'none' }}
                >
                  <Heading size="xs" textTransform="uppercase">
                    Escalabilidad de Contenido
                  </Heading>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel mt="5px" p={0}>
                  <Text fontSize="sm">
                    Para las marcas, puede ser desafiante y costoso producir
                    constantemente nuevo contenido. El UGC permite a las marcas
                    escalar su producción de contenido sin necesariamente
                    incurrir en altos costes.
                  </Text>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Card>
        </Box>

        {/* How it works section */}
        <Box maxW={800} margin="0 auto 20px auto">
          <Card borderRadius="8px" p={5} pb={10}>
            <Heading ml={1} mb={5} fontSize="24px">
              ¿Cómo funciona el UGC en CRUWI?
            </Heading>

            <Image src={UGCHowItWorksCRUWI} />
          </Card>
        </Box>

        <Box maxW={800} margin="0 auto 50px auto">
          <Button
            color="white"
            bg="black"
            _hover={{ bg: 'black', color: 'white' }}
            width="100%"
            onClick={() => navigateTo('/dashboard/ugc/new')}
          >
            Crear briefing
          </Button>
        </Box>
      </Box>
    </>
  );
}
