import {
  Box,
  Flex,
  Grid,
  GridItem,
  Text,
  Button,
  useDisclosure,
  useBreakpointValue,
} from '@chakra-ui/react';
import { QuestionIcon } from '@chakra-ui/icons';

import CustomLink from '../CustomLink/CustomLink';
import HelpModal from '../HelpModal/HelpModal';
import { useGetUserDataQuery } from '../../Redux/features/auth/authApiSlice';

// Assuming you have your data in an array of objects
const cardData = [
  {
    title: '¿Quieres llegar a tu público objetivo de forma orgánica?',
    description:
      'Consigue impactar a tu audiencia a través de decenas de nano y micro influencers. Con menos de 5 minutos al día. Solo tienes que lanzar un reto para conseguir que nuestros más de 10.000 nanoinfluencers hablen de tu marca en TikTok.',
    buttonText: 'Crear reto',
    buttonUrl: 'challenges',
  },
  {
    title: '¿Haces anuncios y buscas mejorar el rendimiento?',
    description:
      'Los anuncios que funcionan ya no parecen anuncios. Apóyate en nuestros creadores de UGC para crear anuncios que emocionen, enganchen y conviertan las visitas en ventas. Lanza un briefing gratuito y contrata a los creadores que más te gusten.',
    buttonText: 'Crear briefing',
    buttonUrl: 'ugc/new',
  },
];

const sayHiBasedOnTime = () => {
  const today = new Date();
  let curHr = today.getHours();

  if (curHr < 12) {
    return 'Buenos días';
  } else if (curHr < 18) {
    return 'Buenas tardes';
  } else {
    return 'Buenas noches';
  }
};

export default function DashboardOverview() {
  const { data: user } = useGetUserDataQuery();
  const columns = useBreakpointValue({ base: 1, md: 2 });
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Box bg="white" p="5" borderBottom="1px solid #e2e8f0">
        <Flex alignItems="center" justifyContent="space-between">
          <Text fontWeight="bold" fontSize="24px">
            {sayHiBasedOnTime()} {user?.firstName}
          </Text>
          <Button rightIcon={<QuestionIcon />} onClick={onOpen} size="sm">
            Solicitar ayuda
          </Button>
        </Flex>
        <HelpModal isOpen={isOpen} onClose={onClose} />
      </Box>

      <Box p={5}>
        <Grid templateColumns={`repeat(${columns}, 1fr)`} gap={6}>
          {cardData.map((card, index) => (
            <GridItem w="100%" key={index}>
              <Box
                p={5}
                borderColor="#e2e8f0"
                borderWidth="1px"
                borderRadius="8px"
                minH="280px"
                d="flex"
                flexDirection="column"
                justifyContent="space-between"
                position="relative"
                _hover={{
                  boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                }}
              >
                <Text fontWeight="bold" fontSize="16px">
                  {card.title}
                </Text>
                <Text fontSize="14px" mt={4} mb={8}>
                  {card.description}
                </Text>{' '}
                {card.buttonText && (
                  <CustomLink to={card.buttonUrl}>
                    <Button
                      color="white"
                      bg="black"
                      _hover={{ bg: 'black', color: 'white' }}
                      position="absolute"
                      right={5}
                      bottom={5}
                    >
                      {card.buttonText}
                    </Button>
                  </CustomLink>
                )}
              </Box>
            </GridItem>
          ))}
        </Grid>
      </Box>
    </>
  );
}
