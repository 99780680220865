import { Box, Center, Image } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';

import CruwiLogo from '../../Assets/Images/cruwi-logo.png';

const pulse = keyframes`
  0% { transform: scale(1.5); }
  50% { transform: scale(2.5); }
  100% { transform: scale(3); }
`;

const animation = `${pulse} 2s ease-in-out infinite`;

export default function LogoLoader() {
  return (
    <Center height="100vh" width="100vw" bg="white">
      <Box animation={animation}>
        <Image src={CruwiLogo} alt="Cruwi Logo" width="100px" height="100px" />
      </Box>
    </Center>
  );
}
