import { useSplitTreatments } from '@splitsoftware/splitio-react';
import { useGetBrandDataQuery } from '../Redux/features/auth/authApiSlice';


export function useFeatureFlags(flagNames) {
  const {
    data: brandData,
  } = useGetBrandDataQuery();
  const { treatments, isReady } = useSplitTreatments({ names: flagNames, splitKey: brandData.data._id });
  return isReady ? mapTreatmentsToFeatureFlags(flagNames, treatments) : {};
}

function mapTreatmentsToFeatureFlags(
  flagNames,
  treatments,
) {
  return flagNames.reduce(
    (flags, flagName) => ({
      ...flags,
      [flagName.charAt(0).toLowerCase() + flagName.slice(1)]: treatments?.[flagName]
        ? treatments[flagName].treatment === 'on'
        : false,
    }),
    {},
);
}
