import React from 'react';

import { Outlet } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { logOut } from '../../Redux/features/auth/authSlice';
import { useGetBrandDataQuery, useGetUserDataQuery } from '../../Redux/features/auth/authApiSlice';
import { useGetPlansQuery } from '../../Redux/features/plans/plansApiSlice';

import CustomLink from '../CustomLink/CustomLink';
import FullScreenLoader from '../FullScreenLoader/FullScreenLoader';

import {
  IconButton,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Avatar,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Image,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  List,
  ListItem,
} from '@chakra-ui/react';

import { FiMenu, FiChevronDown } from 'react-icons/fi';
import { FaFileInvoiceDollar } from 'react-icons/fa';

import {
  RiDashboard3Fill,
  RiVideoChatFill,
  RiFileSettingsFill,
  RiPriceTag3Fill,
  RiFileCopy2Fill,
  RiAdvertisementFill,
  RiClipboardFill,
  RiShieldStarFill,
} from 'react-icons/ri';

import getPlanCounts from '../../Helpers/getBrandPlanCount';

import formatCentsToEurs from '../../Utils/formatCentsToEurs';

import './Dashboard.css';
import TidioChat from '../TidioChat/TidioChat';
import { useFeatureFlags } from '../../Hooks/useFeatureFlags';

const LinkItems = hasMissions => [
  {
    name: 'Retos',
    icon: RiVideoChatFill,
    children: [
      {
        name: 'Analítica',
        path: '/dashboard/challenges/analytics',
        icon: RiDashboard3Fill,
      },
      {
        name: 'Mis retos',
        path: '/dashboard/challenges',
        icon: RiVideoChatFill,
      },
    ],
  },
  ...(hasMissions
    ? [
      {
        name: 'Misiones',
        icon: RiVideoChatFill,
        children: [
          {
            name: 'Analítica',
            path: '/dashboard/missions/analytics',
            icon: RiDashboard3Fill,
          },
          {
            name: 'Mis misiones',
            path: '/dashboard/missions',
            icon: RiShieldStarFill,
          },
        ],
      },
    ]
    : []),
  {
    name: 'UGC',
    icon: RiPriceTag3Fill,
    children: [
      {
        name: 'Qué es UGC',
        path: '/dashboard/ugc',
        icon: RiAdvertisementFill,
      },
      {
        name: 'Mis briefings',
        path: '/dashboard/ugc/briefings',
        icon: RiClipboardFill,
      },
    ],
  },
];

// Listado de abajo (seconday)
const getLinkItems2 = (hasBillingSummary) => {
  const items = [
    { name: 'FAQ', path: '/dashboard/faq', icon: RiFileCopy2Fill },
    { name: 'Ajustes', path: '/dashboard/settings', icon: RiFileSettingsFill }];

  if (hasBillingSummary) {
    items.unshift({ name: 'Facturación', path: '/dashboard/billing-summary', icon: FaFileInvoiceDollar });
  }
  return items;
};

export default function Dashboard({ children }) {
  // Datos marca
  const {
    data: brandData,
    isLoading: isLoadingBrandData,
    isSuccess: isSuccessBrandData,
    isError: isErrorBrandData,
  } = useGetBrandDataQuery();
  const { data: user } =
    useGetUserDataQuery();

  // Datos de los planes
  const {
    data: plans,
    isLoading: isLoadingPlans,
    isSuccess: isSuccessPlans,
    isError: isErrorPlans,
  } = useGetPlansQuery();

  // Drawe menu
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Contenido que renderizamos
  let content;

  if (isLoadingBrandData || isLoadingPlans) {
    content = <FullScreenLoader />;
  } else if (isSuccessBrandData && isSuccessPlans) {
    const hasMissions = brandData.data.hasMissions; // Assuming hasMissions is in brandData

    content = (
      <Box minH="100vh" bg="white">
        <SidebarContent
          onClose={() => onClose}
          display={{ base: 'none', md: 'block' }}
          hasMissions={hasMissions}
        />
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full"
        >
          <DrawerContent>
            <SidebarContent onClose={onClose} hasMissions={hasMissions} />
          </DrawerContent>
        </Drawer>
        <MobileNav
          user={user}
          brand={brandData.data}
          plans={plans.data}
          onOpen={onOpen}
          numberOfCredits={0}
        />
        <Box ml={{ base: 0, md: 60 }}>
          {children}
          <Outlet />
        </Box>
        <TidioChat />
      </Box>
    );
  } else if (isErrorBrandData || isErrorPlans) {
    content = <Box m={4}>Something has gone wrong loading the application</Box>;
  }

  return content;
}

const SidebarContent = ({ onClose, hasMissions, ...rest }) => {
  const {billingSummary} = useFeatureFlags(['BillingSummary']);
  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex
        flexDirection="column"
        h="full"
        justifyContent={['', '', 'space-between', 'space-between']}
      >
        <Box>
          <Flex
            h="20"
            alignItems="center"
            mx="8"
            justifyContent="space-between"
          >
            <CustomLink to="/dashboard">
              <Image
                width="100px"
                src="https://uploads-ssl.webflow.com/62ea5c239bacb85550bf44ea/6328573bad60f760ac2b5fbb_CRUWI%20(3).svg"
                alt="CRUWI Logo"
                transition="transform 0.2s ease-in-out"
                _hover={{
                  transform: 'scale(1.1) rotate(-10deg)',
                }}
              />
            </CustomLink>
            <CloseButton
              display={{ base: 'flex', md: 'none' }}
              onClick={onClose}
            />
          </Flex>

          <Accordion allowToggle>
            {LinkItems(hasMissions).map(link =>
              link.children ? (
                <AccordionItem
                  p="1"
                  mx="3"
                  borderRadius="lg"
                  role="group"
                  cursor="pointer"
                  _hover={{
                    bg: 'white',
                  }}
                  border="none"
                  key={link.name}
                  mb={1}
                >
                  <AccordionButton
                    color="#1d1d1f"
                    border="none"
                    borderRadius="lg"
                    bg="#e2e8f0"
                    _hover={{
                      bg: 'black',
                      borderRadius: 'lg',
                      color: 'white',
                    }}
                  >
                    <Box flex="1" textAlign="left" fontWeight="bold">
                      {link.name}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    {link.children.map(childLink => (
                      <NavItemChildren
                        key={childLink.name}
                        path={childLink.path}
                        icon={childLink.icon}
                        onClick={onClose}
                      >
                        {childLink.name}
                      </NavItemChildren>
                    ))}
                  </AccordionPanel>
                </AccordionItem>
              ) : (
                <NavItem
                  key={link.name}
                  path={link.path}
                  icon={link.icon}
                  onClick={onClose}
                >
                  {link.name}
                </NavItem>
              ),
            )}
          </Accordion>
        </Box>

        <Box pb="30px">
          {getLinkItems2(billingSummary).map(link => (
            <NavItem
              onClick={onClose}
              key={link.name}
              path={link.path}
              icon={link.icon}
            >
              {link.name}
            </NavItem>
          ))}
        </Box>
      </Flex>
    </Box>
  );
};

const NavItem = ({ path, icon, onClick, children, ...rest }) => {
  return (
    <CustomLink to={path}>
      <Flex
        align="center"
        p="3"
        mx="4"
        borderRadius="lg"
        role="group"
        color="#1d1d1f"
        cursor="pointer"
        _hover={{
          bg: 'black',
          color: 'white',
        }}
        {...rest}
        onClick={onClick}
      >
        {icon && (
          <Icon
            color="#1d1d1f"
            mr="3"
            fontSize="16"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        <strong>{children}</strong>
      </Flex>
    </CustomLink>
  );
};

const NavItemChildren = ({ path, icon, onClick, children, ...rest }) => {
  return (
    <CustomLink to={path}>
      <Flex
        alignItems="center"
        p="2"
        mx="0"
        color="#1d1d1f"
        role="group"
        cursor="pointer"
        mb={2}
        _hover={{
          textDecoration: 'underline',
        }}
        onClick={onClick}
        {...rest}
      >
        {icon && <Icon color="#1d1d1f" mr="3" fontSize="16" as={icon} />}
        <strong>{children}</strong>
      </Flex>
    </CustomLink>
  );
};

const MobileNav = ({ onOpen, brand, user, plans, numberOfCredits, ...rest }) => {
  const { availablePlans, balance = 0 } = brand;
  const brandPlanCredits = getPlanCounts(plans, availablePlans);
  const dispatch = useDispatch();

  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...rest}
    >
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      {availablePlans && availablePlans.length > 0 ? (
        <Popover>
          <PopoverTrigger>
            <Box
              cursor="pointer"
              bg="#e2e8f0"
              mr={5}
              py={1}
              px={4}
              borderRadius="8px"
            >
              <Text>
                Challenge credits <strong>{availablePlans.length}</strong>
              </Text>
            </Box>
          </PopoverTrigger>
          <PopoverContent ml={5}>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader fontWeight="bold" fontSize="14px">
              Available challenge credits
            </PopoverHeader>
            <PopoverBody>
              <List spacing={0}>
                {brandPlanCredits.map(p => (
                  <ListItem
                    alignItems="center"
                    display="flex"
                    key={p.name}
                    fontSize="14px"
                  >
                    <Text mr={2} fontSize="9px" opacity={0.6}>
                      {p.count > 0 ? '🟢' : '🔴'}
                    </Text>
                    {p.name}:<strong>{p.count}</strong>
                  </ListItem>
                ))}
              </List>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      ) : (
        <Popover>
          <PopoverTrigger>
            <Box
              cursor="pointer"
              bg="#e2e8f0"
              mr={5}
              py={1}
              px={4}
              borderRadius="8px"
            >
              <Text>
                Balance <strong>{formatCentsToEurs(balance)}</strong>
              </Text>
            </Box>
          </PopoverTrigger>
          <PopoverContent ml={5}>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader fontWeight="bold" fontSize="14px">
              ¿Qué es el balance?
            </PopoverHeader>
            <PopoverBody>
              <Text fontSize="12px">
                El balance es el presupuesto no utilizado en retos anteriores.
                Podrás solicitar su devolución o utilizarlo en próximo reto que
                crees.
              </Text>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}

      <Image
        display={{ md: 'none' }}
        width="80px"
        src="https://uploads-ssl.webflow.com/62ea5c239bacb85550bf44ea/6328573bad60f760ac2b5fbb_CRUWI%20(3).svg"
        alt="CRUWI Logo"
      />

      <HStack spacing={{ base: '0', md: '6' }}>
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: 'none' }}
            >
              <HStack>
                <Avatar
                  size={'sm'}
                  src={
                    brand.logoUrl
                      ? brand.logoUrl
                      : 'https://i.ibb.co/bK5fhrs/placeholder.png'
                  }
                />
                <VStack
                  display={{ base: 'none', md: 'flex' }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text fontSize="sm">{user?.firstName}</Text>
                </VStack>
                <Box display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}
            >
              <CustomLink to="settings">
                <MenuItem>Perfil</MenuItem>
              </CustomLink>
              <CustomLink to="settings">
                <MenuItem>Ajustes</MenuItem>
              </CustomLink>
              <MenuDivider />
              <MenuItem onClick={() => dispatch(logOut())}>
                Cerrar sesión
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};
