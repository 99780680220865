import { Box, Table, Thead, Tbody, Tr, Th, Td, Select, Flex, Text } from '@chakra-ui/react';
import { useGetBrandChallengesQuery } from '../../Redux/features/challenges/challengesApiSlice';
import LogoLoader2 from '../../Components/LogoLoader2/LogoLoader2';
import { formatDate } from '../../Helpers/date';
import React, { useState } from 'react';
import formatNumbersStandard from '../../Helpers/formatNumbersStandard';

const monthNames = [
  "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
];
const SPARK_CODE_PRICE = 20;

export default function BillingSummaryView() {
  const { data: brandChallenges, isLoading } = useGetBrandChallengesQuery({
    isMission: false,
  });

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);

  if (isLoading) {
    return <LogoLoader2 />;
  }

  const handleYearChange = (event) => {
    setSelectedYear(parseInt(event.target.value, 10));
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(parseInt(event.target.value, 10));
  };

  // Filter challenges by selected year and month
  const filteredChallenges = brandChallenges?.data.filter(challenge => {
    const launchedDate = new Date(challenge.launchDate);
    return (
      launchedDate.getFullYear() === selectedYear &&
      launchedDate.getMonth() + 1 === selectedMonth
    );
  }) || [];

  return (
    <>
      <Box bg="white" p="5" borderBottom="1px solid #e2e8f0">
        <Flex alignItems="center" justifyContent="space-between">
          <Text fontWeight="bold" fontSize="24px">
            Facturación
          </Text>
        </Flex>
      </Box>
    <Box bg="white" p="5" borderBottom="1px solid #e2e8f0">
      <Flex mb={4} justify="start" gap={4}>
        <Select value={selectedYear} onChange={handleYearChange} width="120px">
          {Array.from({ length: 2 }, (_, i) => new Date().getFullYear() - i).map(year => (
            <option key={year} value={year}>{year}</option>
          ))}
        </Select>
        <Select value={selectedMonth} onChange={handleMonthChange} width="150px">
          {monthNames.map((month, index) => (
            <option key={index + 1} value={index + 1}>{month}</option>
          ))}
        </Select>
      </Flex>

      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Título</Th>
            <Th>Lanzado en</Th>
            <Th>Presupuesto gastado</Th>
            <Th>Spark Codes</Th>
            <Th>Total reto</Th>
          </Tr>
        </Thead>
        <Tbody>
          {filteredChallenges.length > 0 ? (
            filteredChallenges.map((challenge) => {
              const spentBudget = Math.min(challenge.budget, challenge.metrics.spentBudget) / 100
              const sparkCodesAmount = challenge.sparkCodes * SPARK_CODE_PRICE
              return (
                <Tr key={challenge.id}>
                  <Td>{challenge.title}</Td>
                  <Td>{formatDate(challenge.launchDate)}</Td>
                  <Td>{formatNumbersStandard(spentBudget)}€</Td>
                  <Td>{formatNumbersStandard(sparkCodesAmount)}€ </Td>
                  <Td>{formatNumbersStandard(spentBudget + sparkCodesAmount)}€ </Td>
                </Tr>
              )
            })
          ) : (
            <Tr>
              <Td colSpan={4} textAlign="center">Sin retos en este período</Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Box>
    </>
  );
}
